

@font-face {
  font-family: 'CenturyGothicBold';
  src: local('CenturyGothicBold'), url(./fonts/CenturyGothicBold.ttf) format('truetype');
}

@font-face {
  font-family: 'CenturyGothicRegular';
  src: local('CenturyGothicRegular'), url(./fonts/CenturyGothicRegular.ttf) format('truetype');
}

@font-face {
  font-family: 'Quicksand_Book';
  src: local('Quicksand_Book'), url(./fonts/Quicksand_Book.otf) format('truetype');
}
.image {
 display: flex;
 flex-direction: column;
 align-items: center;
}

.area-div,.header-div {
  width: 90vw;
  display: flex;
  flex-direction: row;
 justify-content: space-between;
}

.card-margin-top{
  margin-top: 0px;
}

.bottom-div{
  position: absolute;
  bottom: 20px;
  width: 20%;

  align-self: center;
}
.card-header{
  /* width: 18rem; */
  background-color: #F0B594 !important;
  border: 0px !important;
  font-family: 'CenturyGothicBold';
  font-size: 18px;
  align-items: center !important;
  justify-content: center !important;
}
.disabled-link {
  pointer-events: none;
}

.overlay{
  display: flex;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.5);
  position: absolute;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
.area-style{
background-color: rgba(16,41,53,.3);
position: absolute;
}
.buttons-div{
  display: flex;
  flex-direction: column;
 align-items:center;
    justify-content: center;
}
.social-buttons-div{
  display: flex;
  flex-direction: row;
  justify-content: space-between; 
  width: 100%;
}

.line{
  width: 300px;
  align-self: flex-end
}
@media screen and (max-width:1204px) {
  .buttons-div{
    
    flex-direction: row;
    justify-content: space-between; 

  }
  
  .social-buttons-div{
    flex-direction: column;
    align-items:flex-start;

  }
  .line{
    width: 15vw;
    align-self: flex-end
  }
}


/* @media screen and (max-width: 1275px) {
  .image {
    display: none!important;
  }

} */


@media screen and (max-width: 1240px) {
  .header-div {
    flex-direction: column;
    align-items:center;
    justify-content: center;
  }
  .bottom-div{
    position: fixed;
    bottom: 20px;
    width: 80%;
  }

}

@media screen and (max-width: 674px) {
  .card-margin-top{
    margin-top: 20px;
  }
  .card{
    width: 100vw;
  
  }
  .card-header{
    width: 100vw;
  }
  .area-div{
    flex-direction: column;
    align-items:center;
    justify-content: center;
  }
}

.grayText{
color:rgba(16,41,53,.35) !important
}

.header{
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: center;
  background-color: #102935;
   color: #fff;

}
.col{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
}

.container{
  display: flex;
  flex-direction: column;
   color: #fff;
    margin-top: 20px ;
 
}
.space-between{
  display: flex;
  justify-content: space-between;
}
.list-style{
  width: 90% !important;
    align-self: center;
    border-bottom: 1px solid rgba(0,0,0,.125);
    padding: 10px;
  
}
.list-group{
  width: 100% !important;
  background-color: #FEF8F8;
  border: 0px !important;
  overflow-y: scroll;
  max-height: 60vh;
  padding-bottom: 50px;
}
.card{
  background-color: rgba(253, 244, 239, .7)  !important;
  
  align-items: center;
  border: 0px !important;
  min-height: 610px;
}
.col-card{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
}
.line{
  height: 2px;
  width: 25px;
  background-color: #F0B594
}

.list-col3 {
  columns: 3;
  -webkit-columns: 3;
  -moz-columns: 3;
  padding: 0;
  list-style-type: none;
  padding-top: 10px;
}
.city-button{
  width: 354px;
}
.list-col2 {
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  padding: 0;
  list-style-type: none;
  padding-top: 10px;
}

.margin-top{
  margin-top: 20px;
}
.App-link {
  color: #61dafb;
}

.btn-test{
  background-color: "#F0B594" !important;
  border: 0 !important;
  box-shadow: "1px 1px 5px 5px rgba(0, 0, 0, .05)" !important;
  width: 224 !important;
  font-family: "CenturyGothicBold" !important;
  font-size: 15 !important
}





@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
